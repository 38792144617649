import BasicExample from "./nav"
export default function Header() {
  return (
    <header id ='bar'> 
      
      <BasicExample/>
      {/* <ul className = 'nav' >
        <a href='/home' className = 'headerLink' >Home</a>
        <a href='/about'className = 'headerLink' >About</a>
        <a href='/contact'className = 'headerLink' >Contact</a>
        <a href='/projects'className = 'headerLink' >Projects</a>
      </ul> */}
    </header>
  )
}
